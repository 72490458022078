// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sxrUfc5CUqpgevyCwcO0{display:grid;grid-template-columns:200px 1fr}.JaUMyetEPUDqEZrPWUEQ{max-height:calc(100vh - 64px - 32px);overflow:scroll;padding:0 calc(var(--spacing) * 3) calc(var(--spacing) * 3)}.EraLyZIsU3S0MeSe5Ygu{padding:0 calc(var(--spacing) * 3)}.xCydUDByG3tZ41J81xVn{color:var(--palette-default)}.kHGTMhX5n_kbKkrqd6tq,.kHGTMhX5n_kbKkrqd6tq:visited{color:var(--palette-default)}.kHGTMhX5n_kbKkrqd6tq{text-decoration:none;display:block;padding:var(--spacing) 0;transition:color 200ms;font-weight:600}.kHGTMhX5n_kbKkrqd6tq:hover{color:var(--palette-primary)}.xCydUDByG3tZ41J81xVn:not(:last-child) .kHGTMhX5n_kbKkrqd6tq{border-bottom:1px solid var(--palette-border)}\n", "",{"version":3,"sources":["webpack://./src/Layouts/Panel.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,+BAAgC,CACjC,sBAGC,oCAAqC,CACrC,eAAgB,CAChB,2DAA4D,CAC7D,sBAGC,kCAAmC,CAEnC,sBACE,4BAA6B,CAE7B,oDAEE,4BAA6B,CAC9B,sBAGC,oBAAqB,CACrB,aAAc,CACd,wBAAyB,CACzB,sBAAuB,CACvB,eAAgB,CALjB,4BAQG,4BAA6B,CAhBlC,6DAqBG,6CAA8C","sourcesContent":[".root {\n  display: grid;\n  grid-template-columns: 200px 1fr;\n}\n\n.content {\n  max-height: calc(100vh - 64px - 32px);\n  overflow: scroll;\n  padding: 0 calc(var(--spacing) * 3) calc(var(--spacing) * 3);\n}\n\n.sidebar {\n  padding: 0 calc(var(--spacing) * 3);\n\n  &Item {\n    color: var(--palette-default);\n\n    &Link,\n    &Link:visited {\n      color: var(--palette-default);\n    }\n\n    &Link {\n      text-decoration: none;\n      display: block;\n      padding: var(--spacing) 0;\n      transition: color 200ms;\n      font-weight: 600;\n\n      &:hover {\n        color: var(--palette-primary);\n      }\n    }\n\n    &:not(:last-child) &Link {\n      border-bottom: 1px solid var(--palette-border);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "sxrUfc5CUqpgevyCwcO0",
	"content": "JaUMyetEPUDqEZrPWUEQ",
	"sidebar": "EraLyZIsU3S0MeSe5Ygu",
	"sidebarItem": "xCydUDByG3tZ41J81xVn",
	"sidebarItemLink": "kHGTMhX5n_kbKkrqd6tq"
};
export default ___CSS_LOADER_EXPORT___;
