// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cq7zigOFoCyTx8eJYOdD{display:flex;align-items:center;gap:var(--spacing);justify-content:end}.JEsltbdvmG7WcC8KoWHV{width:32px;text-align:center}\n", "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CACnB,mBAAoB,CACrB,sBAGC,UAAW,CACX,iBAAkB","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  gap: var(--spacing);\n  justify-content: end;\n}\n\n.page {\n  width: 32px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "cq7zigOFoCyTx8eJYOdD",
	"page": "JEsltbdvmG7WcC8KoWHV"
};
export default ___CSS_LOADER_EXPORT___;
