// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qMV25LIf4JDnXFv8D8_F{margin:0 auto;width:200px}.SyEhfgi1UTuudZSlje_V{appearance:none;border:none;cursor:pointer;margin-top:calc(var(--spacing) * 3);word-break:break-all;border-radius:4px;background:var(--palette-shadow-light);font-size:var(--typography-small);font-family:monospace;padding:var(--spacing);transition:var(--transition)}.SyEhfgi1UTuudZSlje_V:hover{background:var(--palette-shadow)}\n", "",{"version":3,"sources":["webpack://./src/components/UploadedImageDialog/UploadedImageDialog.scss"],"names":[],"mappings":"AAAA,sBACE,aAAc,CACd,WAAY,CACb,sBAOC,eAAgB,CAChB,WAAY,CACZ,cAAe,CACf,mCAAoC,CACpC,oBAAqB,CACrB,iBAAkB,CAClB,sCAAuC,CACvC,iCAAkC,CAClC,qBAAsB,CACtB,sBAAuB,CACvB,4BAA6B,CAf/B,4BAEI,gCAAiC","sourcesContent":[".image {\n  margin: 0 auto;\n  width: 200px;\n}\n\n.copyBox {\n  &:hover {\n    background: var(--palette-shadow);\n  }\n\n  appearance: none;\n  border: none;\n  cursor: pointer;\n  margin-top: calc(var(--spacing) * 3);\n  word-break: break-all;\n  border-radius: 4px;\n  background: var(--palette-shadow-light);\n  font-size: var(--typography-small);\n  font-family: monospace;\n  padding: var(--spacing);\n  transition: var(--transition);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "qMV25LIf4JDnXFv8D8_F",
	"copyBox": "SyEhfgi1UTuudZSlje_V"
};
export default ___CSS_LOADER_EXPORT___;
