// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XeXq7bpPN2t789yA0syL{appearance:none;border:1px solid var(--palette-default);border-radius:3px;cursor:pointer;width:16px;height:16px;margin:4px var(--spacing) 4px 0}.XeXq7bpPN2t789yA0syL:before{content:\"\";background-color:transparent;display:block;border-radius:2px;width:8px;height:8px;position:relative;top:3px;left:3px;transition:var(--transition)}.XeXq7bpPN2t789yA0syL:checked:before{background-color:var(--palette-default)}\n", "",{"version":3,"sources":["webpack://./src/components/Checkbox/Checkbox.scss"],"names":[],"mappings":"AAAA,sBACE,eAAgB,CAChB,uCAAwC,CACxC,iBAAkB,CAClB,cAAe,CACf,UAAW,CACX,WAAY,CACZ,+BAAgC,CAPlC,6BAUI,UAAW,CACX,4BAA6B,CAC7B,aAAc,CACd,iBAAkB,CAClB,SAAU,CACV,UAAW,CACX,iBAAkB,CAClB,OAAQ,CACR,QAAS,CACT,4BAA6B,CAnBjC,qCAuBI,uCAAwC","sourcesContent":[".root {\n  appearance: none;\n  border: 1px solid var(--palette-default);\n  border-radius: 3px;\n  cursor: pointer;\n  width: 16px;\n  height: 16px;\n  margin: 4px var(--spacing) 4px 0;\n\n  &:before {\n    content: \"\";\n    background-color: transparent;\n    display: block;\n    border-radius: 2px;\n    width: 8px;\n    height: 8px;\n    position: relative;\n    top: 3px;\n    left: 3px;\n    transition: var(--transition);\n  }\n\n  &:checked:before {\n    background-color: var(--palette-default);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "XeXq7bpPN2t789yA0syL"
};
export default ___CSS_LOADER_EXPORT___;
