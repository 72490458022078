// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lVcTdHaEjb8chqj0gJEg{display:flex;align-items:center;margin-bottom:var(--spacing)}\n", "",{"version":3,"sources":["webpack://./src/components/AttachTagsToPageDialog/AttachTagsToPageDialog.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,kBAAmB,CACnB,4BAA6B","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  margin-bottom: var(--spacing);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "lVcTdHaEjb8chqj0gJEg"
};
export default ___CSS_LOADER_EXPORT___;
