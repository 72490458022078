// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N6iRvhkv2PJgWrbsMtJW{display:flex;align-items:center;margin-bottom:var(--spacing)}.jkA9T35RIgv8DBCJtQED{margin-bottom:calc(var(--spacing) * 3)}\n", "",{"version":3,"sources":["webpack://./src/components/AttachPagesToTagDialog/AttachPagesToTagDialog.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,kBAAmB,CACnB,4BAA6B,CAC9B,sBAGC,sCAAuC","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  margin-bottom: var(--spacing);\n}\n\n.input {\n  margin-bottom: calc(var(--spacing) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "N6iRvhkv2PJgWrbsMtJW",
	"input": "jkA9T35RIgv8DBCJtQED"
};
export default ___CSS_LOADER_EXPORT___;
