// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ky0sIty97s76Dy8CwZ1y{margin-bottom:calc(var(--spacing) * 3)}.FhAQ1ZllanuzPDVOqv5y{text-align:center}.guJYhFuqFMRmKHRFTypP:not(:last-child){margin-bottom:calc(var(--spacing) * 2)}.guJYhFuqFMRmKHRFTypP h6{margin:0 0 var(--spacing)}.h66hYzM6y0WCbtdI57pw{font-size:var(--typography-caption)}\n", "",{"version":3,"sources":["webpack://./src/components/Search/Search.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAuC,CACxC,sBAGC,iBAAkB,CACnB,uCAIG,sCAAuC,CAF3C,yBAMI,yBAA0B,CAC3B,sBAGC,mCAAoC","sourcesContent":[".input {\n  margin-bottom: calc(var(--spacing) * 3);\n}\n\n.loader {\n  text-align: center;\n}\n\n.result {\n  &:not(:last-child) {\n    margin-bottom: calc(var(--spacing) * 2);\n  }\n\n  h6 {\n    margin: 0 0 var(--spacing);\n  }\n\n  &Content {\n    font-size: var(--typography-caption);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "ky0sIty97s76Dy8CwZ1y",
	"loader": "FhAQ1ZllanuzPDVOqv5y",
	"result": "guJYhFuqFMRmKHRFTypP",
	"resultContent": "h66hYzM6y0WCbtdI57pw"
};
export default ___CSS_LOADER_EXPORT___;
