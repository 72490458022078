// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CqkNdVsVN6NHwAaysWeQ{background:var(--palette-background-paper);border-radius:4px;border:1px solid var(--palette-border-light);padding:calc(var(--spacing) * 2)}.CqkNdVsVN6NHwAaysWeQ:not(:last-child){margin-bottom:calc(var(--spacing) * 3)}.MfXLT6aKDtF9n2nGChsn{display:flex;justify-content:space-between;margin:0 0 calc(var(--spacing) * 2)}.JMQ_hm_eOGjEJ9jP82UG{display:flex;gap:var(--spacing)}\n", "",{"version":3,"sources":["webpack://./src/components/Card.scss"],"names":[],"mappings":"AAAA,sBAKE,0CAA2C,CAC3C,iBAAkB,CAClB,4CAA6C,CAC7C,gCAAiC,CARnC,uCAEI,sCAAuC,CACxC,sBASD,YAAa,CACb,6BAA8B,CAC9B,mCAAoC,CAEpC,sBACE,YAAa,CACb,kBAAmB","sourcesContent":[".root {\n  &:not(:last-child) {\n    margin-bottom: calc(var(--spacing) * 3);\n  }\n\n  background: var(--palette-background-paper);\n  border-radius: 4px;\n  border: 1px solid var(--palette-border-light);\n  padding: calc(var(--spacing) * 2);\n}\n\n.title {\n  display: flex;\n  justify-content: space-between;\n  margin: 0 0 calc(var(--spacing) * 2);\n\n  &Toolbar {\n    display: flex;\n    gap: var(--spacing);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CqkNdVsVN6NHwAaysWeQ",
	"title": "MfXLT6aKDtF9n2nGChsn",
	"titleToolbar": "JMQ_hm_eOGjEJ9jP82UG"
};
export default ___CSS_LOADER_EXPORT___;
