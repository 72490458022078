// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._KJEfwaVigUPnu9t5D_e{max-width:var(--site-width);width:100%;padding:calc(var(--spacing) * 3);margin:0 auto}@media (max-width: 1024px){._KJEfwaVigUPnu9t5D_e{max-width:100%}}\n", "",{"version":3,"sources":["webpack://./src/Layouts/Site.scss"],"names":[],"mappings":"AAAA,sBAKE,2BAA4B,CAC5B,UAAW,CACX,gCAAiC,CACjC,aAAc,CAPd,2BADF,sBAEI,cAAe,CAOlB","sourcesContent":[".site {\n  @media (max-width: 1024px) {\n    max-width: 100%;\n  }\n\n  max-width: var(--site-width);\n  width: 100%;\n  padding: calc(var(--spacing) * 3);\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"site": "_KJEfwaVigUPnu9t5D_e"
};
export default ___CSS_LOADER_EXPORT___;
