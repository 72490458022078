// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q5SVAikFpd8GaKOZDdhW>*:not(:last-child){margin-bottom:calc(var(--spacing) * 2)}\n", "",{"version":3,"sources":["webpack://./src/components/AddUserDialog/AddUserDialog.scss"],"names":[],"mappings":"AAAA,yCAEI,sCAAuC","sourcesContent":[".inputs {\n  & > *:not(:last-child) {\n    margin-bottom: calc(var(--spacing) * 2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "q5SVAikFpd8GaKOZDdhW"
};
export default ___CSS_LOADER_EXPORT___;
