// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rI0I_0HK_hrsmEzNndzA{display:flex;justify-content:space-between;margin-bottom:calc(var(--spacing) * 3)}.m0Y_VLfkDe82AWY1mnPm{margin:0}.rlMi5E8FLAvvX7ylCq4v{display:flex;justify-content:flex-end;margin-top:calc(var(--spacing) * 3);gap:var(--spacing)}.RsreTWMWVEbKOJr6V679{flex:none}\n", "",{"version":3,"sources":["webpack://./src/components/Dialog/Dialog.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,6BAA8B,CAC9B,sCAAuC,CACxC,sBAGC,QAAS,CACV,sBAGC,YAAa,CACb,wBAAyB,CACzB,mCAAoC,CACpC,kBAAmB,CACpB,sBAGC,SAAU","sourcesContent":[".title {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: calc(var(--spacing) * 3);\n}\n\n.titleText {\n  margin: 0;\n}\n\n.actions {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: calc(var(--spacing) * 3);\n  gap: var(--spacing);\n}\n\n.close {\n  flex: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "rI0I_0HK_hrsmEzNndzA",
	"titleText": "m0Y_VLfkDe82AWY1mnPm",
	"actions": "rlMi5E8FLAvvX7ylCq4v",
	"close": "RsreTWMWVEbKOJr6V679"
};
export default ___CSS_LOADER_EXPORT___;
