// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bt4s_S8OXqhDALXXcg0e{position:relative}.n5IKW65CwX_YUxBxrjZq{display:flex;width:100%;justify-content:space-between}.CTMb2ptSUdsUzsYreLur{margin:var(--spacing) 0}.fb_dLsv2o63p1NpaPRmD{display:flex;flex-wrap:wrap;gap:var(--spacing)}.EhV0yoeIrLeq6aICAG_c a{display:inline-block}\n", "",{"version":3,"sources":["webpack://./src/pages/site/TagList/TagList.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAkB,CACnB,sBAGC,YAAa,CACb,UAAW,CACX,6BAA8B,CAC/B,sBAIG,uBAAwB,CACzB,sBAGC,YAAa,CACb,cAAe,CACf,kBAAmB,CARvB,wBAYI,oBAAqB","sourcesContent":[".root {\n  position: relative;\n}\n\n.header {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.section {\n  &Header {\n    margin: var(--spacing) 0;\n  }\n\n  &Content {\n    display: flex;\n    flex-wrap: wrap;\n    gap: var(--spacing);\n  }\n\n  a {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Bt4s_S8OXqhDALXXcg0e",
	"header": "n5IKW65CwX_YUxBxrjZq",
	"sectionHeader": "CTMb2ptSUdsUzsYreLur",
	"sectionContent": "fb_dLsv2o63p1NpaPRmD",
	"section": "EhV0yoeIrLeq6aICAG_c"
};
export default ___CSS_LOADER_EXPORT___;
