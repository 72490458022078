// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D8jsQv6eBfLrvbdKu16C{align-items:center;display:grid;grid-template-columns:32px 1fr 32px;gap:calc(var(--spacing) * 2)}.XBJUL4Lyx5fyXja0C6PM{max-height:600px;margin:0 auto}.K6nSxlvsHjXBdIP1qg7M{margin-top:calc(var(--spacing) * 3);text-align:center}\n", "",{"version":3,"sources":["webpack://./src/components/GalleryPreview/GalleryPreview.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAmB,CACnB,YAAa,CACb,mCAAoC,CACpC,4BAA6B,CAC9B,sBAGC,gBAAiB,CACjB,aAAc,CACf,sBAGC,mCAAoC,CACpC,iBAAkB","sourcesContent":[".root {\n  align-items: center;\n  display: grid;\n  grid-template-columns: 32px 1fr 32px;\n  gap: calc(var(--spacing) * 2);\n}\n\n.image {\n  max-height: 600px;\n  margin: 0 auto;\n}\n\n.text {\n  margin-top: calc(var(--spacing) * 3);\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "D8jsQv6eBfLrvbdKu16C",
	"image": "XBJUL4Lyx5fyXja0C6PM",
	"text": "K6nSxlvsHjXBdIP1qg7M"
};
export default ___CSS_LOADER_EXPORT___;
