// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NtEBMUNXy9WFk8fMoRVh{display:grid;grid-template-columns:2fr 1fr;gap:calc(var(--spacing) * 3)}\n", "",{"version":3,"sources":["webpack://./src/pages/panel/Settings/Settings.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,6BAA8B,CAC9B,4BAA6B","sourcesContent":[".root {\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  gap: calc(var(--spacing) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NtEBMUNXy9WFk8fMoRVh"
};
export default ___CSS_LOADER_EXPORT___;
