// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aWeOLISAJ43iKy1jqTCw{align-items:center;box-shadow:none;display:grid;grid-template-columns:300px 1fr 32px;gap:calc(var(--spacing) * 2);margin-bottom:calc(var(--spacing) * 3);padding:calc(var(--spacing) * 2)}.aWeOLISAJ43iKy1jqTCw:first-child,.aWeOLISAJ43iKy1jqTCw:last-child{margin-bottom:0}.MUZNLsMBxrvkft3L8tKw{display:grid;grid-template-columns:2fr 1fr;gap:calc(var(--spacing) * 3)}.YiW5PY9nYuE2Hjh3Zdhj{margin-top:calc(var(--spacing) * 3)}\n", "",{"version":3,"sources":["webpack://./src/pages/panel/TagEdit/TagEdit.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAmB,CACnB,eAAgB,CAChB,YAAa,CACb,oCAAqC,CACrC,4BAA6B,CAC7B,sCAAuC,CACvC,gCAAiC,CAPnC,mEAWI,eAAgB,CACjB,sBAID,YAAa,CACb,6BAA8B,CAC9B,4BAA6B,CAC9B,sBAGC,mCAAoC","sourcesContent":[".item {\n  align-items: center;\n  box-shadow: none;\n  display: grid;\n  grid-template-columns: 300px 1fr 32px;\n  gap: calc(var(--spacing) * 2);\n  margin-bottom: calc(var(--spacing) * 3);\n  padding: calc(var(--spacing) * 2);\n\n  &:first-child,\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  gap: calc(var(--spacing) * 3);\n}\n\n.pages {\n  margin-top: calc(var(--spacing) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "aWeOLISAJ43iKy1jqTCw",
	"grid": "MUZNLsMBxrvkft3L8tKw",
	"pages": "YiW5PY9nYuE2Hjh3Zdhj"
};
export default ___CSS_LOADER_EXPORT___;
