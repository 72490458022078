// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VC5cvtKqpJc5KNJTDUpX{position:fixed;width:100vw;height:100vh;z-index:1;background:var(--palette-shadow)}\n", "",{"version":3,"sources":["webpack://./src/components/PageActionLoader/PageActionLoader.scss"],"names":[],"mappings":"AAAA,sBACE,cAAe,CACf,WAAY,CACZ,YAAa,CACb,SAAU,CACV,gCAAiC","sourcesContent":[".root {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  z-index: 1;\n  background: var(--palette-shadow);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "VC5cvtKqpJc5KNJTDUpX"
};
export default ___CSS_LOADER_EXPORT___;
