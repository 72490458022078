// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".koxDux50Po3myXYwRBtg{box-shadow:none;display:grid;grid-template-columns:300px 1fr;gap:calc(var(--spacing) * 2);margin-bottom:calc(var(--spacing) * 3);padding:calc(var(--spacing) * 2)}.koxDux50Po3myXYwRBtg:first-child,.koxDux50Po3myXYwRBtg:last-child{margin-bottom:0}\n", "",{"version":3,"sources":["webpack://./src/pages/panel/TagList/TagList.scss"],"names":[],"mappings":"AAAA,sBACE,eAAgB,CAChB,YAAa,CACb,+BAAgC,CAChC,4BAA6B,CAC7B,sCAAuC,CACvC,gCAAiC,CANnC,mEAUI,eAAgB","sourcesContent":[".item {\n  box-shadow: none;\n  display: grid;\n  grid-template-columns: 300px 1fr;\n  gap: calc(var(--spacing) * 2);\n  margin-bottom: calc(var(--spacing) * 3);\n  padding: calc(var(--spacing) * 2);\n\n  &:first-child,\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "koxDux50Po3myXYwRBtg"
};
export default ___CSS_LOADER_EXPORT___;
