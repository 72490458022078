// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zD1Xudhab8IvNOUSYpXI{align-items:center;box-shadow:none;display:grid;grid-template-columns:300px 1fr 32px;gap:calc(var(--spacing) * 2);margin-bottom:calc(var(--spacing) * 3);padding:calc(var(--spacing) * 2)}.zD1Xudhab8IvNOUSYpXI:first-child,.zD1Xudhab8IvNOUSYpXI:nth-child(2),.zD1Xudhab8IvNOUSYpXI:last-child{margin-bottom:0}.E_wZMALJPj6vqPzuwrmX{border-color:var(--palette-warning)}.E_wZMALJPj6vqPzuwrmX .TR8DqaVNbiaL5iwZfOYH{color:var(--palette-warning)}.u6kDrAPsGt4wRs6x0zqA{display:grid;grid-template-columns:2fr 1fr;gap:calc(var(--spacing) * 3)}\n", "",{"version":3,"sources":["webpack://./src/pages/panel/PageList.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAmB,CACnB,eAAgB,CAChB,YAAa,CACb,oCAAqC,CACrC,4BAA6B,CAC7B,sCAAuC,CACvC,gCAAiC,CAPnC,sGAYI,eAAgB,CACjB,sBAGC,mCAAoC,CADrC,4CAIG,4BAA6B,CAC9B,sBAKH,YAAa,CACb,6BAA8B,CAC9B,4BAA6B","sourcesContent":[".item {\n  align-items: center;\n  box-shadow: none;\n  display: grid;\n  grid-template-columns: 300px 1fr 32px;\n  gap: calc(var(--spacing) * 2);\n  margin-bottom: calc(var(--spacing) * 3);\n  padding: calc(var(--spacing) * 2);\n\n  &:first-child,\n  &:nth-child(2),\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  &Highlighted {\n    border-color: var(--palette-warning);\n\n    & &Icon {\n      color: var(--palette-warning);\n    }\n  }\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  gap: calc(var(--spacing) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "zD1Xudhab8IvNOUSYpXI",
	"itemHighlighted": "E_wZMALJPj6vqPzuwrmX",
	"itemHighlightedIcon": "TR8DqaVNbiaL5iwZfOYH",
	"grid": "u6kDrAPsGt4wRs6x0zqA"
};
export default ___CSS_LOADER_EXPORT___;
