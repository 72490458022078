// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lfBU5CYkfZGcMWq9SY1_{position:relative}.I_3aQpgb3P7J8UhL0EFv{display:flex;width:100%;justify-content:space-between}.VWPpIRCXT2OUC_BrWxkU{margin:var(--spacing) 0}.iqOVtBIZbUTlIiFpiqui{display:flex;flex-wrap:wrap;gap:var(--spacing)}.hPOpQ_abd6NmgRqUsOHg a{display:inline-block}\n", "",{"version":3,"sources":["webpack://./src/pages/site/TagPages/TagPages.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAkB,CACnB,sBAGC,YAAa,CACb,UAAW,CACX,6BAA8B,CAC/B,sBAIG,uBAAwB,CACzB,sBAGC,YAAa,CACb,cAAe,CACf,kBAAmB,CARvB,wBAYI,oBAAqB","sourcesContent":[".root {\n  position: relative;\n}\n\n.header {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.section {\n  &Header {\n    margin: var(--spacing) 0;\n  }\n\n  &Content {\n    display: flex;\n    flex-wrap: wrap;\n    gap: var(--spacing);\n  }\n\n  a {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "lfBU5CYkfZGcMWq9SY1_",
	"header": "I_3aQpgb3P7J8UhL0EFv",
	"sectionHeader": "VWPpIRCXT2OUC_BrWxkU",
	"sectionContent": "iqOVtBIZbUTlIiFpiqui",
	"section": "hPOpQ_abd6NmgRqUsOHg"
};
export default ___CSS_LOADER_EXPORT___;
