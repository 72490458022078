// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slHmYYZNe98w9EdVxNxS{align-items:center;border-bottom:1px solid var(--palette-border);display:flex;justify-content:space-between;margin-bottom:calc(var(--spacing) * 3);padding-bottom:var(--spacing)}.slHmYYZNe98w9EdVxNxS h1{margin:0}.hw_WcmlhlxQeFAYgD226{display:flex;gap:var(--spacing)}\n", "",{"version":3,"sources":["webpack://./src/components/PanelHeader/PanelHeader.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAmB,CACnB,6CAA8C,CAC9C,YAAa,CACb,6BAA8B,CAC9B,sCAAuC,CACvC,6BAA8B,CANhC,yBASI,QAAS,CACV,sBAID,YAAa,CACb,kBAAmB","sourcesContent":[".root {\n  align-items: center;\n  border-bottom: 1px solid var(--palette-border);\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: calc(var(--spacing) * 3);\n  padding-bottom: var(--spacing);\n\n  h1 {\n    margin: 0;\n  }\n}\n\n.toolbar {\n  display: flex;\n  gap: var(--spacing);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "slHmYYZNe98w9EdVxNxS",
	"toolbar": "hw_WcmlhlxQeFAYgD226"
};
export default ___CSS_LOADER_EXPORT___;
