// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zBjJ20FgW3JZUF85XDoR{display:flex;justify-content:flex-end;position:fixed;bottom:0;width:100%;left:0;padding:calc(var(--spacing) * 2) calc(var(--spacing) * 3);background:var(--palette-background);box-shadow:0px -1px 10px 0px var(--palette-shadow);gap:var(--spacing)}\n", "",{"version":3,"sources":["webpack://./src/components/Savebar.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,wBAAyB,CACzB,cAAe,CACf,QAAS,CACT,UAAW,CACX,MAAO,CACP,yDAA0D,CAC1D,oCAAqC,CACrC,kDAAmD,CACnD,kBAAmB","sourcesContent":[".root {\n  display: flex;\n  justify-content: flex-end;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  left: 0;\n  padding: calc(var(--spacing) * 2) calc(var(--spacing) * 3);\n  background: var(--palette-background);\n  box-shadow: 0px -1px 10px 0px var(--palette-shadow);\n  gap: var(--spacing);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "zBjJ20FgW3JZUF85XDoR"
};
export default ___CSS_LOADER_EXPORT___;
