// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GbNONYAz_tEyMPlXhuaE{display:grid;grid-template-columns:1fr 1fr;gap:calc(var(--spacing) * 3)}.d2HT4V0hKNXOA6fzbUuO:not(:last-child){margin-bottom:calc(var(--spacing) * 2)}\n", "",{"version":3,"sources":["webpack://./src/pages/panel/Home/Home.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,6BAA8B,CAC9B,4BAA6B,CAC9B,uCAIG,sCAAuC","sourcesContent":[".root {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: calc(var(--spacing) * 3);\n}\n\n.page {\n  &:not(:last-child) {\n    margin-bottom: calc(var(--spacing) * 2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "GbNONYAz_tEyMPlXhuaE",
	"page": "d2HT4V0hKNXOA6fzbUuO"
};
export default ___CSS_LOADER_EXPORT___;
