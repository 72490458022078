// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZsdwBwDTkpf4XWOEYMoN{display:grid;grid-template-columns:2fr 1fr;gap:calc(var(--spacing) * 3)}.sCudAYr2r6WLEQsz6Bx8>*:not(:last-child){margin-bottom:calc(var(--spacing) * 2)}.dcJBfTzqLoDzRuxZcfpP{margin-bottom:var(--spacing)}\n", "",{"version":3,"sources":["webpack://./src/pages/panel/Account/Account.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,6BAA8B,CAC9B,4BAA6B,CAC9B,yCAIG,sCAAuC,CACxC,sBAID,4BAA6B","sourcesContent":[".root {\n  display: grid;\n  grid-template-columns: 2fr 1fr;\n  gap: calc(var(--spacing) * 3);\n}\n\n.inputs {\n  & > *:not(:last-child) {\n    margin-bottom: calc(var(--spacing) * 2);\n  }\n}\n\n.passwordChange {\n  margin-bottom: var(--spacing);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ZsdwBwDTkpf4XWOEYMoN",
	"inputs": "sCudAYr2r6WLEQsz6Bx8",
	"passwordChange": "dcJBfTzqLoDzRuxZcfpP"
};
export default ___CSS_LOADER_EXPORT___;
