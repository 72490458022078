// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u2YZbmbqt3ZyFRJOU8pS{display:flex;align-items:center;justify-content:center;height:50vh}\n", "",{"version":3,"sources":["webpack://./src/pages/common/PageLoading.scss"],"names":[],"mappings":"AAAA,sBACE,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,WAAY","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 50vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "u2YZbmbqt3ZyFRJOU8pS"
};
export default ___CSS_LOADER_EXPORT___;
